<template>
  <div class="main_container col-all-12" v-cloak>
    <div class="_container col-all-12">
      <InformationBanner></InformationBanner>
      <CookieBanner></CookieBanner>
      <router-view />
      <Footer></Footer>
    </div>
  </div>
</template>
<style>
[v-cloak] {
  display: none;
}
body {
  height: 100%;
}
</style>

<style lang="less" src="@/assets/less/global.less"></style>
<style src="@/assets/css/main-flex.css"></style>
<script>
import InformationBanner from '@/components/InformationBanner.vue'
import CookieBanner from '@/components/CookieBanner.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    InformationBanner,
    CookieBanner,
    Footer
  },
  mounted() {
    let announcekitScript = document.createElement('script')
    announcekitScript.async = true
    announcekitScript.setAttribute('src', 'https://cdn.announcekit.app/widget.js')
    document.head.appendChild(announcekitScript)
    const waitForAnnouncekit = () => {
      if (window['announcekit']) {
        window.announcekit = window.announcekit || {
          queue: [],
          on: function(n, x) {
            window.announcekit.queue.push([n, x])
          },
          push: function(x) {
            window.announcekit.queue.push(x)
          }
        }
        window.announcekit.push({
          widget: 'https://announcekit.app/widget/2Cn6uY',
          version: 2
        })
      } else {
        setTimeout(() => waitForAnnouncekit(), 100)
      }
    }
    waitForAnnouncekit()
  }
}
</script>
